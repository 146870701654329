const config = {
    ONLINE_BOOKING_BACKEND_V2 : "https://obbv2demo.shipsy.in",
    PROJECT_X_URL: "https://posdm-webhook-changes-demo.demoprojectxsandbox7.shipsy.io",
    GOOGLE_RECAPTCHA_SITE_KEY: "6Ld8BOQUAAAAAHjZ7AyFHmMUlr1gyXjyXFER9GYX",
    ORGANISATION_LOGO_URL:
      "https://s3-us-west-2.amazonaws.com/shipsy-public-assets/Zajil/Zajil.jpg",
    applicationType: "feregistration",
    DEFAULT_PRETTY_NAME: "qwiklogistics", //org for which you are testing
    organisationId: "qwiklogistics", //org for which you are testing
    hardcodedDataOrgs: ["ZAJIL"],
    hideAreaCodeOrgs: ["FLOWPL", "epickmeup"],
    apiKey: "8tskcdaRpARZQTu2JCjMAmdBBkpEsk2u",
  };
  export default config; 
