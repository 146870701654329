import * as base64 from "base-64";

let injectedData = null;
const injectedDataString =
  (window as any).injectedConfig ||
  base64.encode(
    JSON.stringify({
      applicationType: "zajilebooking",
    })
  );
const dataJson = base64.decode(injectedDataString);
injectedData = JSON.parse(dataJson);

export const applicationType = (injectedData as any).applicationType;

export { injectedData };
