import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Radio,
  Button,
  Modal,
  Row,
  Col,
  message,
  Upload,
  Select,
  Spin
} from "antd";
import { createUseStyles } from "react-jss";
import ReCAPTCHA from "react-google-recaptcha";
import * as lodash from "lodash";
import { useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import type { RcFile } from "antd/es/upload";

import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../../globals";
import { setPageLanguage } from "../../../actions/performTranslation";
import {
  registerAppUser,
  putImageS3,
  getAppUserOtp,
  verifyAppUserOtp,
} from "../../../api/appUserSignup";
import { addressMasterData } from "../../../data/addressMasterData";
const { Option } = Select;

const styles = createUseStyles({
  header: {
    padding: "8px 24px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.4)",
    fontSize: 16,
    fontWeight: 600,
    color: "#424242",
    alignItems: "center",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    background: "#ffffff",
    zIndex: +1,
  },
  bookingFormFields: {
    padding: 36,
    boxSizing: "border-box",
    "& label::after": {
      content: '"" !important',
    },
    "& label.ant-form-item-required::before": {
      display: "none",
    },
    "& label.ant-form-item-required::after": {
      display: "inline-block",
      marginRight: "4px",
      color: "#ff4d4f",
      fontSize: "14px",
      lineHeight: 1,
      content: '"*" !important',
    },
    "& label.ant-radio-wrapper": {
      minWidth: "120px",
    },
    "& .ant-form-item-control-input": {
      maxWidth: "400px",
    },
    "& .ant-input-number ": {
      width: "100%",
    },
  },
  phoneNumberField: {
    "& .ant-input-group-rtl": {
      direction: "ltr",
      borderLeft: "1px solid #d9d9d9",
    },
  },
  formSectionHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: "#424242",
    marginBottom: 20,
  },
  uploadField: {
    display: "flex",
    "& p": {
      color: "#A6B6C3",
      fontSize: "0.8rem",
    },
    "& .ant-upload-select-picture-card": {
      width: "100%",
      height: "auto",
      padding: "2rem",
    },
    "& .ant-upload-list-picture-card": {
      width: "100%",
    },
    "& .ant-upload": {
      flexDirection: "column",
    },
  },
  uploadIcon: {
    fontSize: "2rem",
    color: "#A6B6C3",
    padding: "1rem 0",
  },
  verifyButton: {
    color: "#1890ff",
    cursor: 'pointer',
    fontWeight: 500,
  },
});

interface IdentificationDocument {
  type: string;
  number: string;
  image: string;
}

interface PocContentType {
  type: string;
  contentTypeList: String[];
}
interface AddressOption {
  key: string;
  value: string;
}

let config: any = {
  allowed_language_list: ["ar", "en", "hi"],
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const BookingForm = (props: any) => {
  const intl = useIntl();
  const classes = styles();
  const [googleCaptha, setGoogleCaptcha] = useState(null);
  const [otpGoogleCaptcha, setOTPGoogleCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState("");
  const [otpCaptchaError, setOTPCaptchaError] = useState("");
  const [otpHash, setOtpHash] = useState("");
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [formReset, setFormReset] = useState(0);
  const [isDocMandatoryFields, setIsDocMandatoryFields] = useState<any>([]);
  const [adderssOption, setAdderssOption] = useState<AddressOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState({ key: '', label: '', value: '' });
  const [selectedState, setSelectedState] = useState({ key: '', label: '', value: '' });
  const [selectedCity, setSelectedCity] = useState({ key: '', label: '', value: '' });
  const language = useSelector((state: any) => state.translation.language);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [mobileVerified, setMobileVerified] = React.useState(false);
  const [otpFetched, setOtpFetched] = React.useState(false);

  const { getFieldValue } = form;
  const [fileList, setFileList] = useState({
    drivingLicence: [] as UploadFile[],
    nationalId: [] as UploadFile[],
    aadhaarCard: [] as UploadFile[],
    panCard: [] as UploadFile[],
  });
  const [previewOpen, setPreviewOpen] = useState({
    drivingLicence: false,
    nationalId: false,
    aadhaarCard: false,
    panCard: false,
  });
  const [previewImage, setPreviewImage] = useState({
    drivingLicence: "",
    nationalId: "",
    aadhaarCard: "",
    panCard: "",
  });
  const [previewTitle, setPreviewTitle] = useState({
    drivingLicence: "",
    nationalId: "",
    aadhaarCard: "",
    panCard: "",
  });
  let showCountryStateCity = false;
  let isRiderOnboardingCustom = true;

  let workerFieldsList = lodash.get(config, 'request_approval_config.rider_onboarding_field_list') || [];
  if (!Array.isArray(workerFieldsList) || !workerFieldsList.length) {
    showCountryStateCity = true;
    isRiderOnboardingCustom = false;
    workerFieldsList = lodash.get(config, 'worker_master_config.fields_list') || [];
  }
  let selfOnboardingWorkerTypes = lodash.get(config, 'worker_master_config.self_onboarding_worker_types') || [];
  const selfOnboardingWorkerTypesMandatoryDocuments = lodash.get(config, 'worker_master_config.self_onboarding_worker_types_mandatory_documents') || {};
  const allowedJflWorkerTypes = lodash.get(config, 'allowed_jfl_worker_types') || [];
  selfOnboardingWorkerTypes = selfOnboardingWorkerTypes.filter(workerType =>
    allowedJflWorkerTypes.includes(workerType)
  );
  const workerAllFields = workerFieldsList.map((field) => field.key);
  const identificationDocuments = workerFieldsList?.filter(field => field.key === "identification_documents")[0]?.required_documents || [];

  const isFieldRequired = (key) => {
    const field = workerFieldsList.find(field => field.key === key);
    return !field?.is_optional;
  };
  const generateNationalIDTypeOptions = () => {
    if (!Array.isArray(identificationDocuments) || !identificationDocuments.length) {
      return [];
    }
    const nationalIDTypeOptions = identificationDocuments.filter((key) => key.is_national_type).map((key) => {
      return {
        label: key.label,
        value: key.key
      };
    });
    const options = nationalIDTypeOptions.map((e, index) =>
      <Option
        key={index}
        value={e.value}>{e.label}
      </Option>
    );
    return options;
  };

  const isOnboardingField = (field) => {
    return workerAllFields.includes(field);
  };

  useEffect(() => {
    setGoogleCaptcha(null);
    setOTPGoogleCaptcha(null);
  }, [language]);
  useEffect(() => {
    form.setFieldsValue({ state: { key: '', label: '', value: '' } });
    form.setFieldsValue({ city: { key: '', label: '', value: '' } });
    setSelectedState({ key: '', label: '', value: '' });
    setSelectedCity({ key: '', label: '', value: '' });
  }, [selectedCountry]);
  useEffect(() => {
    setSelectedCity({ key: '', label: '', value: '' });
    form.setFieldsValue({ city: { key: '', label: '', value: '' } });
  }, [selectedState]);

  const validateFileType = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isAllowedType = isLt5M;
    if (!isAllowedType) {
      return false;
    }
    return true;
  };

  const uploadProps = (type) => {
    return {
      beforeUpload: (file) => {
        if (!validateFileType(file)) {
          message.error("Image must smaller than 5MB!");
          return Upload.LIST_IGNORE;
        }
        return true;
      },
      onRemove: (file: UploadFile) => {
        if (fileList[type].some((item) => item.uid === file.uid)) {
          setFileList({
            ...fileList,
            [type]: fileList[type].filter((item) => item.uid !== file.uid),
          });
          return true;
        }
        return false;
      },
      onPreview: async (file: UploadFile) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewImage({
          ...previewImage,
          [type]: file.url || (file.preview as string),
        });
        setPreviewOpen({
          ...previewOpen,
          [type]: true,
        });
        setPreviewTitle({
          ...previewTitle,
          [type]:
            file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1),
        });
      },
      onChange: ({ file, fileList: newFileList }) => {
        setFileList({
          ...fileList,
          [type]: newFileList,
        });
      },
    };
  };

  const uploadImages = async (uploadUrl, type) => {
    try {
      const fileUploads = fileList[type].map(async (image, index) => {
        const fileItem = fileList[type]?.[index];
        const signedUrl = uploadUrl[index];
        const file = new Blob([fileItem.originFileObj as BlobPart], {
          type: fileItem.type,
        });

        await putImageS3(signedUrl, file, fileItem.type);
      });
      await Promise.all(fileUploads);
      return fileUploads;
    } catch (err) {
      console.log(err);
      modal.error({
        title: "Could Not Process Request",
        content: "Please try again later",
        onOk() {
          setProcessingSubmit(false);
        },
      });
    }
  };

  const getImageContentTypeList = (type) => {
    const contentType = [] as String[];
    fileList[type].map(async (image) => {
      const fileItem = image;
      contentType.push(fileItem.type?.split("/")[1]);
    });
    return contentType;
  };

  const submitForm = async (values: any) => {
    setProcessingSubmit(true);
    let isImageUploaded = {
      drivingLicence: false,
      nationalId: false,
      aadhaarCard: false,
      panCard: false,
    };

    const captcha = googleCaptha;
    if (!captcha) {
      setCaptchaError(intl.formatMessage({ id: "captchaError" }));
      setProcessingSubmit(false);
      return;
    }
    let preferredLocality = '';
    if (values.city && values.state && values.country) {
      preferredLocality = `${values.city.trim()}-${values.state.trim()}-${values.country.trim()}`.toUpperCase();
    }
    var name = values.name;
    const firstName = values.firstName;
    const lastName = values.lastName;
    if (values.lastName && values.firstName) {
      name = values.firstName + ' ' + values.lastName;
    }
    const requestData = {
      ...values,
      type: "CREATEWORKER",
      objectType: "WORKER",
      preferredLocality: preferredLocality,
      name,
      firstName,
      lastName,
      captcha,
      nationalIdType: values.nationalIdType,
      employeeType: values.employeeType,
      operationalTime: values.operationalTime,
    } as { [key: string]: any };
    delete requestData.city;
    delete requestData.country;
    delete requestData.state;
    if (isOnboardingField('otp')) {
      if (!otpFetched) {
        message.error('Please verify phone number!');
        setProcessingSubmit(false);
        return;
      }
      if (!otpHash) {
        message.error('Please get OTP!');
        setProcessingSubmit(false);
        return;
      }
      requestData.otp = values.otp;
      requestData.hash = otpHash;
    }

    if (isOnboardingField('nationalIdType') && form.getFieldValue('nationalIdType')) {
      requestData.nationalIdNumber = values[form.getFieldValue('nationalIdType')] || '';
    }
    console.log(isOnboardingField('hubCode'));
    console.log(form.getFieldValue('hubCode'));
    if (isOnboardingField('hubCode') && form.getFieldValue('hubCode')) {
      requestData.preferredLocality = form.getFieldValue('hubCode');
    }

    const identificationDocuments: IdentificationDocument[] = [];
    const pocContentTypeList: PocContentType[] = [];
    if (values.panCard && getImageContentTypeList("panCard")) {
      const panCardPocContentTypeList = getImageContentTypeList("panCard");
      pocContentTypeList.push({ type: 'panCard', contentTypeList: panCardPocContentTypeList });
      identificationDocuments.push({ type: 'panCard', number: values.panCard, image: '' });
      delete requestData.panCard;
    }
    if (values.aadhaarCard && getImageContentTypeList("aadhaarCard")) {
      const aadhaarCardPocContentTypeList = getImageContentTypeList("aadhaarCard");
      pocContentTypeList.push({ type: 'aadhaarCard', contentTypeList: aadhaarCardPocContentTypeList });
      identificationDocuments.push({ type: 'aadhaarCard', number: values.aadhaarCard, image: '' });
      delete requestData.aadhaarCard;
    }
    if (values.nationalId && getImageContentTypeList("nationalId")) {
      const nationalIdPocContentTypeList = getImageContentTypeList("nationalId");
      pocContentTypeList.push({ type: 'nationalId', contentTypeList: nationalIdPocContentTypeList });
      identificationDocuments.push({ type: 'nationalId', number: values.nationalId, image: '' });
      delete requestData.nationalId;
    }
    if (values.drivingLicence && getImageContentTypeList("drivingLicence")) {
      const drivingLicencePocContentTypeList = getImageContentTypeList("drivingLicence");
      pocContentTypeList.push({ type: 'drivingLicence', contentTypeList: drivingLicencePocContentTypeList });
      identificationDocuments.push({ type: 'drivingLicence', number: values.drivingLicence, image: '' });
      delete requestData.drivingLicence;
    }
    requestData.identificationDocuments = identificationDocuments;
    requestData.pocContentTypeList = pocContentTypeList;
    console.log(requestData);

    const response = await registerAppUser(requestData);
    if (response.isSuccess) {
      const imageUploadUrl = {
        drivingLicence: lodash.get(
          response,
          "data.data.drivingLicenceNumberSignedUrlList",
          []
        ),
        nationalId: lodash.get(
          response,
          "data.data.nationalIdNumberSignedUrlList",
          []
        ),
        aadhaarCard: lodash.get(
          response,
          "data.data.aadhaarCardNumberSignedUrlList",
          []
        ),
        panCard: lodash.get(
          response,
          "data.data.panCardNumberSignedUrlList",
          []
        ),
      };
      await uploadImages(
        imageUploadUrl["drivingLicence"],
        "drivingLicence"
      ).then(() => {
        isImageUploaded = {
          ...isImageUploaded,
          drivingLicence: true,
        };
      });
      await uploadImages(
        imageUploadUrl["nationalId"],
        "nationalId"
      ).then(() => {
        isImageUploaded = {
          ...isImageUploaded,
          nationalId: true,
        };
      });
      await uploadImages(
        imageUploadUrl["aadhaarCard"],
        "aadhaarCard"
      ).then(() => {
        isImageUploaded = {
          ...isImageUploaded,
          aadhaarCard: true,
        };
      });
      await uploadImages(
        imageUploadUrl["panCard"],
        "panCard"
      ).then(() => {
        isImageUploaded = {
          ...isImageUploaded,
          panCard: true,
        };
      });

      if (isImageUploaded.nationalId && isImageUploaded.drivingLicence && isImageUploaded.aadhaarCard && isImageUploaded.panCard) {
        modal.success({
          title: "Registration confirmed!",
          content: (
            <p>{intl.formatMessage({ id: "registrationConfirmed" })} </p>
          ),
          onOk() {
            form.resetFields();
            setFileList({
              drivingLicence: [] as UploadFile[],
              nationalId: [] as UploadFile[],
              aadhaarCard: [] as UploadFile[],
              panCard: [] as UploadFile[],
            });
            setPreviewTitle({
              drivingLicence: "",
              nationalId: "",
              aadhaarCard: "",
              panCard: "",
            });
            setPreviewImage({
              drivingLicence: "",
              nationalId: "",
              aadhaarCard: "",
              panCard: "",
            });
            setFormReset(Number(!formReset));
            setProcessingSubmit(false);
            setSelectedCountry({ key: '', label: '', value: '' });
          },
        });
      } else {
        modal.error({
          title: "Could Not Process Request",
          content:
            "There was a problem uploading the images, please try again in a moment.",
          onOk() {
            setProcessingSubmit(false);
          },
        });
      }

    } else {
      modal.error({
        title: "Could Not Process Request",
        content: response.errorMessage,
        onOk() {
          setProcessingSubmit(false);
        },
      });
    }
  };

  const captchaChange = (captchaVal: any) => {
    setGoogleCaptcha(captchaVal);
    if (captchaVal && captchaError) {
      setCaptchaError("");
    }
  };
  const otpCaptchaChange = (captchaVal: any) => {
    setOTPGoogleCaptcha(captchaVal);
    if (captchaVal && otpCaptchaError) {
      setOTPCaptchaError("");
    }
  };

  const genderList = [
    {
      key: 'Male',
      value: 'male'
    },
    {
      key: 'Female',
      value: 'female'
    },
    {
      key: 'Not Specified',
      value: 'not_specified'
    }
  ];

  const generateGenderOptions = (featureItems: any) => {
    return featureItems?.map((d: any) => {
      return {
        value: d?.value,
        label: d?.key,
      };
    });
  };

  const generateAddressDropDown = () => {
    return adderssOption.map((d: any) => {
      return {
        value: d.value,
        key: d.key,
      };
    }
    )
  };

  const generateWorkerTypeOption = (selfOnboardingWorkerTypes) => {
    return selfOnboardingWorkerTypes?.map((opt: any) => {
      return {
        value: opt,
        key: opt,
      };
    })
  };

  const handleCancel = (type) => {
    setPreviewOpen({
      ...previewOpen,
      [type]: false,
    });
  };

  const handleAddressSearch = async (searchQuery, nodeType) => {
    if (!searchQuery.trim()) {
      setAdderssOption([]);
      return;
    }

    let response: any = [];
    if (nodeType === 'city') {
      response = addressMasterData[selectedCountry.key][selectedState.key].map((node) => {
        return {
          key: node,
          value: node
        };
      })
    } else if (nodeType === 'state') {
      response = Object.keys(addressMasterData[selectedCountry.key]).map((node) => {
        return {
          key: node,
          value: node
        };
      })
    } else if (nodeType === 'country') {
      response = Object.keys(addressMasterData).map((node) => {
        return {
          key: node,
          value: node
        };
      })
    }
    setAdderssOption(response);
  };

  const handleAddressClick = async (q, nodeType) => {
    nodeType = nodeType.trim().toLowerCase();
    if (nodeType === 'country') {
      setSelectedCountry({
        label: q,
        key: q,
        value: q
      });
    } else if (nodeType === 'state') {
      setSelectedState({
        label: q,
        key: q,
        value: q
      });
    } else if (nodeType === 'city') {
      setSelectedCity({
        label: q,
        key: q,
        value: q
      });
    }
    setAdderssOption([]);
  };

  const generateEmployeeTypeOptions = () => {
    let employeeTypeOptions = config?.allowed_jfl_worker_types;
    if (!(Array.isArray(employeeTypeOptions) && employeeTypeOptions.length)) {
      employeeTypeOptions = [
        { label: 'Business', value: 'business' },
        { label: 'Partner', value: 'partner' },
      ];
    }
    const options = employeeTypeOptions.map((entry, index) =>
      <Option
        key={index}
        value={entry}>{entry}
      </Option>
    );
    return options;
  };

  const generateOperationalTimeOptions = () => {
    let operationalTimeOptions = config?.worker_master_config?.operational_time_options;
    if (!(Array.isArray(operationalTimeOptions) && operationalTimeOptions.length)) {
      operationalTimeOptions = [
        { label: 'Part Time', value: 'part_time' },
        { label: 'Full Time', value: 'full_time' }
      ];
    }
    const options = operationalTimeOptions.map((e, index) =>
      <Option
        key={index}
        value={e.value}>{e.label}
      </Option>
    );
    return options;
  };

  const getOtp = async () => {
    if (!form.getFieldValue('phoneNumber')) {
      message.error('Please enter phone number!');
      return;
    }
    const captcha = otpGoogleCaptcha;
    if (!captcha && isOnboardingField('otp')) {
      setOTPCaptchaError(intl.formatMessage({ id: "otpCaptchaError" }));
      return;
    }
    const response = await getAppUserOtp({
      phone: form.getFieldValue('phoneNumber'),
      captcha: captcha,
    });
    console.log(response);
    if (response.isSuccess && response.data) {
      setOtpFetched(true);
      setOtpHash(response.data);
    } else {
      message.error(response.errorMessage);
    }
  }

  return (<>
    {
      !workerFieldsList.length ?
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Spin tip="Loading..." />
        </div>
        :
        <Form
          form={form}
          onFinish={submitForm}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          labelAlign="left"
        >
          {contextHolder}
          <div className={classes.bookingFormFields}>
            {workerFieldsList.length ?
              <div className={classes.formSectionHeader}>
                {intl.formatMessage({ id: "personalDetails" })} :
              </div>
              : null
            }
            {isOnboardingField('workerType') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "workerType" })} ::`}
                name="workerType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={`${intl.formatMessage({ id: "workerType" })} ::`}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  onSelect={(q) => {
                    if (isRiderOnboardingCustom) {
                      setIsDocMandatoryFields(selfOnboardingWorkerTypesMandatoryDocuments[q] || []);
                    }
                  }}
                  options={generateWorkerTypeOption(selfOnboardingWorkerTypes)}
                />
              </Form.Item>
              : null
            }
            {isOnboardingField('name') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "name" })} ::`}
                name="name"
                rules={[
                  {
                    required: isFieldRequired('name'),
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: "name" })} />
              </Form.Item>
              : null}
            {isOnboardingField('firstName') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "firstName" })} ::`}
                name="firstName"
                rules={[
                  {
                    required: isFieldRequired('firstName'),
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: "firstName" })} />
              </Form.Item>
              : null}
            {isOnboardingField('lastName') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "lastName" })} ::`}
                name="lastName"
                rules={[
                  {
                    required: isFieldRequired('lastName'),
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: "lastName" })} />
              </Form.Item>
              : null}
            {isOnboardingField('phone') || isOnboardingField('phoneNumber') ?
              <><Form.Item
                label={`${intl.formatMessage({ id: "phoneNumber" })} ::`}
                name="phoneNumber"
                rules={[
                  {
                    required: isFieldRequired('phone') || isFieldRequired('phoneNumber'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setMobileVerified(false);
                    form.setFieldsValue({ phoneNumber: e.target.value });
                  }}
                  placeholder={intl.formatMessage({ id: "phoneNumber" })}
                />
              </Form.Item>
              </>
              : null
            }
            {
              isOnboardingField('otp') && !mobileVerified && !otpFetched ? (
                <>
                  <Form.Item label=":">
                    <div>
                      <ReCAPTCHA
                        sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                        onChange={otpCaptchaChange}
                        hl={language}
                        key={`${language}${formReset}`}
                      />
                      <span style={{ color: "red" }}>{otpCaptchaError}</span>
                    </div>
                  </Form.Item>
                </>) : null
            }
            {
              isOnboardingField('otp') && !mobileVerified && !otpFetched ? (
                <>
                  <Form.Item label=":">
                    <div>
                      <Button
                        className={classes.verifyButton}
                        onClick={getOtp}>
                        {intl.formatMessage({ id: "verifyPhoneNumber" })}
                      </Button>
                    </div>
                  </Form.Item>
                </>) : null
            }
            {isOnboardingField('otp') && otpFetched ?
              <><Form.Item
                label={`${intl.formatMessage({ id: "otp" })} ::`}
                name="otp"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={mobileVerified}
                  type="password"
                  placeholder={intl.formatMessage({ id: "otpEnter" })}
                />
              </Form.Item>
              </>
              : null
            }
            {isOnboardingField('email') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "email" })} ::`}
                name="email"
                rules={[
                  {
                    required: isFieldRequired('email'),
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder={intl.formatMessage({ id: "email" })}
                />
              </Form.Item>
              : null
            }
            {isOnboardingField('locality_field') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "localityField" })} ::`}
                name="localityField"
                rules={[
                  {
                    required: isFieldRequired('locality_field'),
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={intl.formatMessage({ id: "localityField" })}
                />
              </Form.Item>
              : null
            }
            {isOnboardingField('gender') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "gender" })} ::`}
                name="gender"
                rules={[
                  {
                    required: isFieldRequired('gender'),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={'Search Gender'}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  notFoundContent={null}
                  options={generateGenderOptions(genderList)}
                />
              </Form.Item>
              : null
            }

            {(workerFieldsList.length && showCountryStateCity) ?
              <Form.Item
                label={`${intl.formatMessage({ id: "country" })} ::`}
                name="country"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search Country"
                  value={selectedCountry}
                  defaultActiveFirstOption={false}
                  onSearch={(q) => handleAddressSearch(q, 'country')}
                  showArrow={false}
                  onSelect={(q) => handleAddressClick(q, 'country')}
                  notFoundContent={null}
                  options={generateAddressDropDown()}
                />
              </Form.Item>
              : null
            }
            {(workerFieldsList.length && showCountryStateCity) ?
              <Form.Item
                label={`${intl.formatMessage({ id: "state" })} ::`}
                name="state"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search State"
                  disabled={!selectedCountry.key}
                  value={selectedState}
                  defaultActiveFirstOption={false}
                  onSearch={(q) => handleAddressSearch(q, 'state')}
                  showArrow={false}
                  onSelect={(q) => handleAddressClick(q, 'state')}
                  notFoundContent={null}
                  options={generateAddressDropDown()}
                />
              </Form.Item>
              : null
            }

            {(workerFieldsList.length && showCountryStateCity) ?
              <Form.Item
                label={`${intl.formatMessage({ id: "city" })} ::`}
                name="city"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  value={selectedCity}
                  disabled={!selectedCountry.key || !selectedState.key}
                  placeholder="Search City"
                  defaultActiveFirstOption={false}
                  onSearch={(q) => handleAddressSearch(q, 'city')}
                  showArrow={false}
                  onSelect={(q) => handleAddressClick(q, 'city')}
                  notFoundContent={null}
                  options={generateAddressDropDown()}
                />
              </Form.Item>
              : null}


            {isOnboardingField('worker_type') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "workerType" })} ::`}
                name="workerType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Worker Type"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  onSelect={(q) => handleAddressClick(q, 'city')}
                  notFoundContent={null}
                  options={generateWorkerTypeOption(selfOnboardingWorkerTypes)}
                />
              </Form.Item>
              : null
            }

            {isOnboardingField('preferred_time') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "preferredTime" })} ::`}
                name="preferredTime"
                rules={[
                  {
                    required: isFieldRequired('preferred_time'),
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: "preferredTime" })}
                />
              </Form.Item>
              : null
            }

            <div className={classes.formSectionHeader}>
              {intl.formatMessage({ id: "documents" })} :
            </div>

            {isOnboardingField('nationalIdType') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "nationalIdType" })} ::`}
                name="nationalIdType"
                rules={[
                  {
                    required: isFieldRequired('nationalIdType'),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={`${intl.formatMessage({ id: 'nationalIdType' })}`}
                >
                  {generateNationalIDTypeOptions()}
                </Select>
              </Form.Item>
              : null}

            {identificationDocuments?.map((document) => {
              return (document.is_applicable_for_self_onboarding && document.key) ? (
                <div>
                  <Form.Item
                    label={`${intl.formatMessage({ id: `${document.key}Number` })} ::`}
                    name={`${document.key}`}
                    rules={[
                      {
                        required: isRiderOnboardingCustom ?
                          isDocMandatoryFields.includes(document.key) : !document.is_optional,
                      },
                    ]}
                  >
                    <Input placeholder={intl.formatMessage({ id: `${document.key}Number` })} />
                  </Form.Item>
                  <Form.Item
                    label={`${intl.formatMessage({ id: `${document.key}Photo` })} ::`}
                    name={`${document.key}Photo`}
                    rules={[
                      {
                        required: isRiderOnboardingCustom ?
                          isDocMandatoryFields.includes(document.key) : !document.is_optional,
                      },
                    ]}
                  >
                    <Upload
                      accept=".png, .jpg, .jpeg"
                      multiple={true}
                      maxCount={2}
                      showUploadList={true}
                      listType="picture-card"
                      className={classes.uploadField}
                      fileList={fileList[`${document.key}`]}
                      {...uploadProps(`${document.key}`)}
                    >
                      <UploadOutlined className={classes.uploadIcon} />
                      <div>{intl.formatMessage({ id: "chooseFile" })}</div>
                      <p>- {intl.formatMessage({ id: "maxiumumPhotosAllowed" })}</p>
                      <p>- {intl.formatMessage({ id: "maxiumumSizeAllowed" })}</p>
                      <p>- {intl.formatMessage({ id: "allowedFormats" })}</p>
                    </Upload>
                  </Form.Item>
                </div>
              ) : null;
            })
            }
            {isOnboardingField('hubCode') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "hubCode" })} ::`}
                name="hubCode"
                rules={[
                  {
                    required: isFieldRequired('hubCode'),
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({ id: "hubCode" })} />
              </Form.Item>
              : null}

            {isOnboardingField('employee_type') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "employeeType" })} ::`}
                name="employeeType"
                rules={[
                  {
                    required: isFieldRequired('employeeType'),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={`${intl.formatMessage({ id: 'employeeType' })}`}
                >
                  {generateEmployeeTypeOptions()}
                </Select>
              </Form.Item>
              : null}

            {isOnboardingField('operational_time') ?
              <Form.Item
                label={`${intl.formatMessage({ id: "operationalTime" })} ::`}
                name="operationalTime"
                rules={[
                  {
                    required: isFieldRequired('operationalTime'),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder={`${intl.formatMessage({ id: 'operationalTime' })}`}
                >
                  {generateOperationalTimeOptions()}
                </Select>
              </Form.Item>
              : null}

            <Form.Item label=":">
              <div>
                <ReCAPTCHA
                  sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={captchaChange}
                  hl={language}
                  key={`${language}${formReset}`}
                />
                <span style={{ color: "red" }}>{captchaError}</span>
              </div>
            </Form.Item>
            <Form.Item label=":">
              <Button
                style={{ width: "100%" }}
                size="large"
                type="primary"
                htmlType="submit"
                loading={processingSubmit}
              >
                {intl.formatMessage({ id: "submit" })}
              </Button>
            </Form.Item>
          </div>
          <Modal
            open={previewOpen["nationalId"]}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={previewImage["nationalId"]}
            />
          </Modal>
          <Modal
            open={previewOpen["drivingLicence"]}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={previewImage["drivingLicence"]}
            />
          </Modal>
          <Modal
            open={previewOpen["aadhaarCard"]}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={previewImage["aadhaarCard"]}
            />
          </Modal>
          <Modal
            open={previewOpen["panCard"]}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={previewImage["panCard"]}
            />
          </Modal>
        </Form>
    }
  </>
  );
};

const FERegistration = (props: any) => {
  const classes = styles();
  const intl = useIntl();
  const language = useSelector((state: any) => state.translation.language);
  const dispatch = useDispatch();
  const masterData = useSelector((state: any) => state.masterdata);
  const handleLanguageChange = (e) => {
    dispatch(setPageLanguage(e.target.value));
  };

  config = {
    ...config,
    ...masterData.booking_portal_config,
    worker_master_config: masterData?.worker_master_config || {},
    allowed_jfl_worker_types: masterData?.allowed_jfl_worker_types || [],
    request_approval_config: masterData?.request_approval_config || {},
  };
  if (config.allowed_language_list.length === 1)
    dispatch(setPageLanguage(config.allowed_language_list[0]));

  return (
    <div style={{ marginTop: "56px" }}>
      <Row justify="space-between" className={classes.header}>
        <Col>
          <div>
            {masterData.logo_url && (
              <img
                alt="Organisation Logo"
                style={{ height: 44, margin: "0px 8px" }}
                src={masterData.logo_url}
              />
            )}
            <span style={{ verticalAlign: "middle" }}>
              {intl.formatMessage({ id: "feRegistrationHeader" })}
            </span>
          </div>
        </Col>
        <Col>
          <div>
            {config.allowed_language_list.length > 0 && (
              <Radio.Group value={language} onChange={handleLanguageChange}>
                {config.allowed_language_list.map((e, idx) => (
                  <Radio.Button value={e} key={idx}>
                    {intl.formatMessage({ id: e })}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
          </div>
        </Col>
      </Row>
      <BookingForm metadata={config} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    masterdata: state.masterdata,
  };
};

export default connect(mapStateToProps)(FERegistration);
