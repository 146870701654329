const config = require('./config').default;

export const {
    GOOGLE_RECAPTCHA_SITE_KEY,
    PROJECT_X_URL,
    ONLINE_BOOKING_BACKEND_V2,
    DEFAULT_PRETTY_NAME,
    ORGANISATION_LOGO_URL,
    applicationType,
    organisationId,
    hardcodedDataOrgs,
    hideAreaCodeOrgs,
} = config;

export const zajilServiceTypes = [
    {
        id: 'normal'
    },
    {
        id: 'super'
    },
];

export const validOriginAreaCodes = ['DMM','JED','RUH','MKH','MED2','KMS2','TIF'];

export const validAreaCodes = ["ABH", "AJRM", "ABUARSH", "DILM", "DRYA", "AHDMASAR", "AHDRFD", "ANDR", "DLMY", "HARQ", "HLWH", "JFR", "LTH", "TUW", "BADSH", "AFLAJ", "AWMY", "BAD", "ABT", "BAK", "HWYH", "JOMM", "KHAB", "KFJ", "KRG", "KHRMA", "Majmah", "MKHWA", "MUZ", "MUBRZ", "MZH", "QTF", "QDEH", "QNFD", "URY", "QWYA", "QUZ", "OLA", "UYON", "WJH", "NBHN", "ANK", "RWDH", "RAE", "SULIL", "THQB", "BDR", "BHR", "BHRCW", "BLJRSH", "BRQ", "BTHA", "BSH", "BHH", "BQQ", "BRD", "DMD", "DMM", "DRB", "DWD", "DHRN", "DHJAN", "DRM", "DBA", "DKHN", "JNDL", "GHT", "HFR", "HAS", "HALIQF", "HQL", "HOF", "HWSD", "HOBT", "HRMLA", "JSH", "JED", "GIZ", "JBL", "KMS2", "MED1", "KHBR", "LYLA", "MHL", "MJRD", "MLHM", "MKH", "MED2", "NYRH", "EAM", "NKE", "NMS", "AQI", "QRYAUL", "RBGH", "RAH", "RNYA", "RSTNRH", "RAS", "RJMA", "RUH", "RYDKHO", "RMH", "SBTULY", "SABY", "SFWA", "SEHT", "SJR", "AJF", "SLBKH", "SLWA", "SAMT", "SRTABD", "SHQR", "SHW", "TBRJ", "TUU", "TIF", "TRF", "TRT", "TATHL", "TYM", "THL", "TRB", "TUI", "UDLYA", "UMLJ", "ONEZ", "UQSR", "UTHM", "UJWA", "WAE", "WDBH", "YNB", "ZLM", "ZLF", "AFIF", "BLSMR", "KMSTNM", "BRDTNM", "JEDDHB", "HADTH", "KKMC"];
