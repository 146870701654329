import { organisationId } from '../globals';
import { applicationType } from '../utils/globals';
import store from '../store/store';

export const getAuthenticationHeaders = () => {
  const localStorage = window.localStorage;
  const code = localStorage.getItem('bookingcode');
  return {
    'organisation-id': store.getState().masterdata.organisation_id || organisationId,
    'application-type': applicationType,
  };
};
export const getErrorMessage = (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection!';
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;

    } else {
      errorMessage = `Something went wrong! \n${err.message}`;
    }
  }
  return errorMessage;
};