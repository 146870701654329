import apiProvider from "./provider";
import { getAuthenticationHeaders, getErrorMessage } from "./utils";
import { PROJECT_X_URL, ONLINE_BOOKING_BACKEND_V2 } from "../globals";
import { REGISTER_APP_USER, GET_S3_SIGNED_URL,
  GET_APP_USER_OTP, VERIFY_APP_USER_OTP
 } from "./routes";
import { POST, GET } from "./apiHandler";

export const registerAppUser = async (body) => {
  return POST(`${PROJECT_X_URL}${REGISTER_APP_USER}`, body);
};

export const putImageS3 = async (url, file, content_type) => {
  try {
    const response = await apiProvider.put(url, file, {
      headers: {
        "Content-Type": content_type,
      },
    });
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getS3SignedUrl = async (body) => {
  return POST(`${PROJECT_X_URL}${GET_S3_SIGNED_URL}`, body);
};

export const getAppUserOtp = async (body) => {
  return POST(`${PROJECT_X_URL}${GET_APP_USER_OTP}`, body);
};

export const verifyAppUserOtp = async (body) => {
  return POST(`${PROJECT_X_URL}${VERIFY_APP_USER_OTP}`, body);
};
